<template>
  <div>
    <!--<PageHeader pagetitle="Services" :img="img"/>-->
    <v-data-table
    :headers="headers"
    :items="departmentServices"
    :items-per-page="15"
    dense
    class="elevation-1"
    >
           
    <template #item.Url="{ item }">
        <a target="_blank" :href="$api_url+item.Url">
            {{ item.Name }}
        </a>
    </template>
   
    </v-data-table>  
  </div>
  
 
</template>

<script>
//import PageHeader from '@/components/PageHeader';
//import servicesData from "@/json/services.json";
export default {
  name : 'Services',
  props : ['link'],
  components: {
    //PageHeader
  },
  data () {
    return {
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      services : [],
      departmentServices:[],
      headers: [ 
        { text: 'SERVICES NAME', align: 'start', value: 'Url' },
         ],
    
    }
  },

  mounted()  {
    if(this.link=="ALL"){
      this.headers=[ {   
          text: 'DEPARTMENT NAME', 
          value: 'Department', 
          align: 'start',
        },
        { text: 'DOCUMENT NAME', 
          value: 'Url', 
          align: 'start',
        },
      ]
       //call filter to get department services
      //getAllDependentRecords/:tablename/:tablename2/:field2/:joinby/:joinby2/:filter/:order
      this.getAllDependentRecords(`/getAllDependentRecords/services/departments/Link/Department/Name/where services.ID > 0/services.ID asc`,'services');
   
    }
    else{
      this.headers=[ 
        { text: 'DOCUMENT NAME',
          value: 'Url',
          align: 'start',
         },
      ]
       //call filter to get department services
      //getAllDependentRecords/:tablename/:tablename2/:field2/:joinby/:joinby2/:filter/:order
      this.getAllDependentRecords(`/getAllDependentRecords/services/departments/Link/Department/Name/where Link='`+this.link+`'/ID asc`,'services');
   
    }
   
  
  },

  watch: {
    link () {
      //this.departmentServices=this.filterServices(this.link); 
      this.departmentServices=[]
      this.getAllDependentRecords(`/getAllDependentRecords/services/departments/Link/Department/Name/where Link='`+this.link+`'/ID asc`,'services');
   
    }
  }, 

  methods : {

    filterServices: function(link) {
      //shortcut to get department services
      return this.services.filter( xservices => xservices.link==link)
      //other way to extract data
      //return services.link == this.link
    },

     getAllDependentRecords:function(query,table){
        //alert(query);       
        this.$axios.get( this.$api_url+query,{
            
          params: {
               
            }
        })
        .then(response => {
            //alert(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
                
                if (table=='departments') {
                     this.departments=response.data;
                     //this.filterDepartment(this.link);
                     //alert(this.departments);
                }
                else if (table=='services'){
                  console.log(response.data)
                    this.departmentServices=response.data;
                    //this.departmentDownloadables=response.data;
                }
                else if (table=='departmentservices'){
                    this.departmentServices=response.data;
                    //this.departmentDownloadables=response.data;
                }
                else if (table=='departmentdetails'){
                    this.departmentDetails2=response.data;
                    //alert(this.departmentDetails2);  
                    //this.departmentDownloadables=response.data;
                }
                
                
                //return response.data;
                //this.departments=response.data;
                //console.log(response.data);
                
              }
        })
        .catch(error => { console.log(error)})
        
    },  

  }
}
</script>

<style>
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>